import './NewTransaction.css';

function NewTransaction({ actions, openRaw, openAction, cancel }) {

  return (
    <div className="newtransaction">
      <div className="section">
        <div className="header">From Scratch</div>
        <div className="option" onClick={openRaw}>Create transaction from scratch</div>
      </div>
      <div className="section">
        <div className="header">From Action</div>
        { actions.map(action => <div className="option" key={action.name} onClick={() => openAction(action)}>{action.name}</div>) }
      </div>
      <div className="section">
        <div className="header">Other</div>
        <div className="option" onClick={cancel}>Cancel</div>
      </div>
    </div>
  );
}

export default NewTransaction;
