const url_prefix = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001/api';

const backend = {
  createLedger: async (name, preset) => {
    return await fetch(`${url_prefix}/ledger`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name, preset })
      });
  },

  fetchLedgerConfig: async (ledgerid) => {
    return await fetch(`${url_prefix}/ledger/${ledgerid}`);
  },

  fetchLedgerActions: async (ledgerid) => {
    return await fetch(`${url_prefix}/ledger/${ledgerid}/actions`);
  },

  fetchLedgerTransactions: async (ledgerid) => {
    return await fetch(`${url_prefix}/ledger/${ledgerid}/transactions`);
  },

  fetchDataExport: async (ledgerid) => {
    return await fetch(`${url_prefix}/ledger/${ledgerid}/dataexport`)
  },

  calculateAccountBalance: async (ledgerid, transactionId) => {
    return await fetch(`${url_prefix}/ledger/${ledgerid}/calculateaccountbalance`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ transactionId: transactionId })
    });
  },

  insertLedgerTransaction: async (ledgerid, transaction) => {
    return await fetch(`${url_prefix}/ledger/${ledgerid}/transactions`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(transaction)
    });
  },

  updateLedgerTransaction: async (ledgerid, transaction) => {
    return await fetch(`${url_prefix}/ledger/${ledgerid}/transactions/${transaction.id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(transaction)
    });
  },

  deleteLedgerTransaction: async (ledgerid, transactionId) => {
    return await fetch(`${url_prefix}/ledger/${ledgerid}/transactions/${transactionId}`,
      {
        method: 'DELETE'
    });
  },

  moveLedgerTransaction: async (ledgerid, transactionId, moveAfterId) => {
    return await fetch(`${url_prefix}/ledger/${ledgerid}/transactions/${transactionId}/move`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ moveAfterId })
    });
  },

  saveConfig: async (ledgerid, config) => {
    return await fetch(`${url_prefix}/ledger/${ledgerid}/config`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(config)
    });
  },

  addType: async (ledgerid, typename) => {
    return await fetch(`${url_prefix}/ledger/${ledgerid}/types`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name: typename })
    });
  },

  addAccount: async (ledgerid, accountname, increaseInCredit) => {
    return await fetch(`${url_prefix}/ledger/${ledgerid}/accounts`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name: accountname, increaseInCredit: increaseInCredit })
    });
  },

  createAction: async (ledgerid, action) => {
    return await fetch(`${url_prefix}/ledger/${ledgerid}/actions`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(action)
    });
  },

  updateAction: async (ledgerid, action) => {
    return await fetch(`${url_prefix}/ledger/${ledgerid}/actions/${action.id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(action)
    });
  },

  deleteAction: async (ledgerid, actionId) => {
    return await fetch(`${url_prefix}/ledger/${ledgerid}/actions/${actionId}`,
      {
        method: 'DELETE'
    });
  }
};

export default backend;