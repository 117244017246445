import { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare as regularPlus } from '@fortawesome/free-regular-svg-icons';
import { faPlusSquare as solidPlus } from '@fortawesome/free-solid-svg-icons';
import './Configuration.css';
import AccountConfiguration from './AccountConfiguration';
import GroupConfiguration from './GroupConfiguration';
import TypeConfiguration from './TypeConfiguration';

function Configuration({ config, cancelConfig, saveConfig, editActions, exportData }) {
  const [newConfig, setNewConfig] = useState(JSON.parse(JSON.stringify(config)));
  const [showAddVirtualAccount, setShowAddVirtualAccount] = useState(false);
  const [addVirtualAccountName, setAddVirtualAccountName] = useState("");
  const [editVirtualAccountId, setEditVirtualAccountId] = useState(null);
  const [editVirtualAccountName, setEditVirtualAccountName] = useState("");
  const [editVirtualAccountPlusAccount, setEditVirtualAccountPlusAccount] = useState(null);
  const [editVirtualAccountMinusAccount, setEditVirtualAccountMinusAccount] = useState(null);
  const [stopEditSignals, setStopEditSignals] = useState({ accounts: Date.now(), groups: Date.now(), virtualAccounts: Date.now(), types: Date.now() });

  const addNewVirtualAccount = useCallback(() => {
    newConfig.virtualAccounts["new_" + new Date().getTime()] = { name: addVirtualAccountName };
    setNewConfig({ ...newConfig });
    setAddVirtualAccountName("");
    setShowAddVirtualAccount(false);
  }, [addVirtualAccountName, setNewConfig, newConfig, setAddVirtualAccountName, setShowAddVirtualAccount]);

  const stopEditVirtualAccount = useCallback(() => {
    if (editVirtualAccountId) {
      newConfig.virtualAccounts[editVirtualAccountId].name = editVirtualAccountName.trim();
      newConfig.virtualAccounts[editVirtualAccountId].plusAccountId = editVirtualAccountPlusAccount;
      newConfig.virtualAccounts[editVirtualAccountId].minusAccountId = editVirtualAccountMinusAccount;
      setEditVirtualAccountId(null);
      setEditVirtualAccountPlusAccount(null);
      setEditVirtualAccountMinusAccount(null)
    }
  }, [newConfig, editVirtualAccountId, editVirtualAccountName, setEditVirtualAccountId, setEditVirtualAccountPlusAccount, setEditVirtualAccountMinusAccount, editVirtualAccountPlusAccount, editVirtualAccountMinusAccount]);

  const stopEditEverythingExcept = useCallback((exception) => {
    const newSignals = { accounts: Date.now(), groups: Date.now(), virtualAccounts: Date.now(), types: Date.now() };
    setStopEditSignals({ ...newSignals, [exception]: stopEditSignals[exception] });
    stopEditVirtualAccount();
  }, [stopEditVirtualAccount, setStopEditSignals, stopEditSignals]);

  const editVirtualAccount = useCallback(accountId => {
    stopEditEverythingExcept();
    setEditVirtualAccountId(accountId);
    setEditVirtualAccountName(newConfig.virtualAccounts[accountId].name);
    setEditVirtualAccountPlusAccount(newConfig.virtualAccounts[accountId].plusAccountId);
    setEditVirtualAccountMinusAccount(newConfig.virtualAccounts[accountId].minusAccountId);
  }, [newConfig, stopEditEverythingExcept, setEditVirtualAccountId, setEditVirtualAccountName, setEditVirtualAccountPlusAccount, setEditVirtualAccountMinusAccount]);

  return (
    <>
      <div className="configuration" onClick={stopEditEverythingExcept}>
        <div className="name">
          <div className="header">Ledger Name</div>
          <input type="text" className="ledgername" value={newConfig.name} onChange={e => setNewConfig({ ...newConfig, name: e.target.value })}></input>
        </div>
        <GroupConfiguration
          groups={newConfig.accountGroups}
          setGroups={(groups) => setNewConfig({ ...newConfig, accountGroups: groups })}
          stopAllEditsExcept={stopEditEverythingExcept}
          stopEditSignal={stopEditSignals.groups} />
        <AccountConfiguration
          accounts={newConfig.accounts}
          setAccounts={(accounts) => setNewConfig({ ...newConfig, accounts: accounts})}
          groups={newConfig.accountGroups}
          defaultGroupId={Object.keys(newConfig.accountGroups)[0]}
          stopAllEditsExcept={stopEditEverythingExcept}
          stopEditSignal={stopEditSignals.accounts} />
        <div className="virtualaccounts">
          <div className="header">Virtual Accounts
            <span className="headeraddbutton" onClick={() => setShowAddVirtualAccount(true)}>
              <FontAwesomeIcon icon={regularPlus} className="buttonUnfocused"/>
              <FontAwesomeIcon icon={solidPlus} className="buttonFocused"/>
            </span>
          </div>
          <div className="virtualaccountrow virtualaccountrowheader"><div className="nameheader name">Name</div><div className="compriseaccountheader plusaccount">Plus account</div><div className="compriseaccountheader minusaccount">Minus account</div></div>
          { showAddVirtualAccount &&
            <div className="newvirtualaccount">
              <input autoFocus className="newvirtualaccountname" type="text" placeholder="Name" value={addVirtualAccountName} onChange={e => setAddVirtualAccountName(e.target.value)}></input>
              <button onClick={() => addNewVirtualAccount()}>Add</button>
            </div>
          }
          { Object.keys(newConfig.virtualAccounts).map(virtualAccountId => 
            <div className="virtualaccountrow" key={virtualAccountId} onClick={(e) => e.stopPropagation()}>
              {
                editVirtualAccountId === virtualAccountId ?
                  <div className="name"><input type="text" autoFocus value={editVirtualAccountName} onChange={e => setEditVirtualAccountName(e.target.value)}></input></div> : 
                  <div className="name" onClick={() => editVirtualAccount(virtualAccountId)}>{newConfig.virtualAccounts[virtualAccountId].name}</div>
              }
              {
                editVirtualAccountId === virtualAccountId ?
                  <div className="plusaccount">
                    <select value={editVirtualAccountPlusAccount} onChange={e => setEditVirtualAccountPlusAccount(e.target.value)}>
                    {
                      Object.keys(newConfig.accounts).map(accountId => <option key={accountId} value={accountId}>{newConfig.accounts[accountId].name}</option>) 
                    }
                    </select>
                  </div> : 
                  <div className="plusaccount" onClick={() => editVirtualAccount(virtualAccountId)}>{newConfig.accounts[newConfig.virtualAccounts[virtualAccountId].plusAccountId]?.name}</div>
              }
              {
                editVirtualAccountId === virtualAccountId ?
                  <div className="minusaccount">
                    <select value={editVirtualAccountMinusAccount} onChange={e => setEditVirtualAccountMinusAccount(e.target.value)}>
                    {
                      Object.keys(newConfig.accounts).map(accountId => <option key={accountId} value={accountId}>{newConfig.accounts[accountId].name}</option>) 
                    }
                    </select>
                  </div> : 
                  <div className="minusaccount" onClick={() => editVirtualAccount(virtualAccountId)}>{newConfig.accounts[newConfig.virtualAccounts[virtualAccountId].minusAccountId]?.name}</div>
              }
            </div>
          ) }
        </div>
        <TypeConfiguration 
          types={newConfig.types}
          setTypes={(types) => setNewConfig({ ...newConfig, types: types })}
          stopAllEditsExcept={stopEditEverythingExcept}
          stopEditSignal={stopEditSignals.types} />
      </div>
      <footer>
        <div className="left">
          <button onClick={editActions}>Edit actions...</button>
          <button onClick={exportData}>Export</button>
        </div>
        <div className="right">
          <button onClick={() => { stopEditEverythingExcept(); saveConfig(newConfig); }}>Save</button>
          <button onClick={cancelConfig}>Cancel</button>
        </div>
      </footer>
    </>
  );
}

export default Configuration;
