import { useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import './CreateLedger.css';
import backend from './backend';

function CreateLedger() {
  const [ledgerName, setLedgerName] = useState("");
  const [ledgerPreset, setLedgerPreset] = useState("");
  const navigate = useNavigate();

  const createLedger = useCallback(() => {
    if (ledgerName.trim()) {
      (async () => {
        const response = await backend.createLedger(ledgerName, ledgerPreset);
        if (response.ok) {
          navigate(`/${(await response.json()).id}`);
        }
      })();
    }
  }, [ledgerName, ledgerPreset, navigate]);

  return (
    <div className="newledgercontainer">
      <div className="spacer"></div>
      <h1>Inventarium</h1>
      <div className="newledgerform">
        <div className="newledgerinputcolumn">
          <div className="newledgerinputrow">
            <span>New ledger:</span>
            <input type="text" placeholder='name of ledger' value={ledgerName} onChange={e => setLedgerName(e.target.value)} autoFocus />
          </div>
          <div className="newledgerinputrow">
            <span>Use preset:</span>
            <select value={ledgerPreset} onChange={e => setLedgerPreset(e.target.value)}>
              <option value="empty">None</option>
              <option value="dnd">D&amp;D</option>
              <option value="mutant">Mutant</option>
            </select>
          </div>
        </div>
        <div className="newledgerbuttoncolumn">
          <button onClick={createLedger} className={ledgerName === "" ? "disabled": ""}>Create</button>
        </div>
      </div>
      <div className="newledgerreminder">After creating your ledger you must save the resulting URL, perhaps by bookmarking it. It is the only way to find it again.</div>
      <div className="spacer"></div>
    </div>
  );
}

export default CreateLedger;
