import { useCallback, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare as regularPlus } from '@fortawesome/free-regular-svg-icons';
import { faPlusSquare as solidPlus } from '@fortawesome/free-solid-svg-icons';

function GroupConfiguration({ groups, setGroups, stopAllEditsExcept, stopEditSignal }) {
  const [showAddGroup, setShowAddGroup] = useState(false);
  const [addGroupName, setAddGroupName] = useState("");
  const [editGroupId, setEditGroupId] = useState(null);

  const stopEdit = useCallback(() => {
    if (editGroupId) {
      setEditGroupId(null);
    }
  }, [editGroupId, setEditGroupId]);

  useEffect(() => {
    stopEdit();
  }, [stopEditSignal]);

  const addNew = useCallback(() => {
    groups["new_" + new Date().getTime()] = addGroupName;
    setGroups(groups);
    setAddGroupName("");
    setShowAddGroup(false);
  }, [groups, addGroupName, setGroups, setAddGroupName, setShowAddGroup]);

  const startEdit = useCallback(groupId => {
    stopAllEditsExcept('groups');
    setEditGroupId(groupId);
  }, [stopAllEditsExcept, setEditGroupId]);

  const setEditGroupName = useCallback((name) => {
    groups[editGroupId] = name;
    setGroups(groups);
  }, [groups, editGroupId, setGroups]);

  return (
    <div className="accountgroups">
      <div className="header">Account Groups
        <span className="headeraddbutton" onClick={() => setShowAddGroup(true)}>
          <FontAwesomeIcon icon={regularPlus} className="buttonUnfocused"/>
          <FontAwesomeIcon icon={solidPlus} className="buttonFocused"/>
        </span>
      </div>
      { showAddGroup &&
        <div className="newgroup">
          <input autoFocus className="newgroupname" type="text" placeholder="Name" value={addGroupName} onChange={e => setAddGroupName(e.target.value)}></input>
          <button onClick={() => addNew()}>Add</button>
        </div>
      }
      {
        Object.keys(groups).map(groupId =>
          <div className="grouprow" key={groupId} onClick={(e) => e.stopPropagation()}>
          {
            editGroupId === groupId ?
              <input type="text" autoFocus value={groups[groupId]} onChange={e => setEditGroupName(e.target.value)} key={'edit_' + groupId}></input> :
              <div className="group" key={groupId} onClick={() => startEdit(groupId)}>{groups[groupId]}</div>
          }
          </div>
        )
      }
    </div>
  );
}

export default GroupConfiguration;
