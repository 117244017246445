import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye as eye, faEyeSlash as eyeSlash } from '@fortawesome/free-regular-svg-icons';
import './Dialog.css';

function Dialog({ header, children }) {
  const [hidden, setHidden] = useState(false);

  return (
    <div className="dialogwrapper">
      <div className="dialogmodal">
        <header><h4>{ header }<span className="dim" onClick={() => setHidden(!hidden)}>{ hidden ? <FontAwesomeIcon icon={eyeSlash} /> : <FontAwesomeIcon icon={eye} />}</span></h4></header>
        <div className={"dialogchildren " + (hidden ? "hidden" : "")}>
          { children }
        </div>
      </div>
    </div>
  );
}

export default Dialog;
