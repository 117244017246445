import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare as regularPlus, faMinusSquare as regularMinus } from '@fortawesome/free-regular-svg-icons';
import { faPlusSquare as solidPlus, faMinusSquare as solidMinus } from '@fortawesome/free-solid-svg-icons';
import util from './util';

const numberFormat = new Intl.NumberFormat();
const numberFormatDecimal = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

function AccountBalance({ config, balance, balanceAccount, transactions, balanceTransactionId, createTransaction, setBalanceTransactionId }) {
  const totalWorth = config && balance && balanceAccount && balance[balanceAccount] &&
    Object.entries(balance[balanceAccount]).reduce((prev, [typeId, value]) => prev + config.types[typeId].worth * value, 0) || 0;

  return (
    <>
      {
        (!config || !balance || !balanceAccount || !balance[balanceAccount] || Object.keys(balance[balanceAccount]).length === 0)
        ? <div className="empty">Balance is empty</div>
        : <ul>
            {
            Object.keys(balance[balanceAccount]).map(type =>
              <li key={type}>
                {numberFormat.format(balance[balanceAccount][type])} {config.types[type].name}
                {
                  config.accounts[balanceAccount] &&
                  <>
                  <span className="minusButton" onClick={() => createTransaction(balanceAccount, type, -1)}><FontAwesomeIcon icon={regularMinus} className="buttonUnfocused"/><FontAwesomeIcon icon={solidMinus} className="buttonFocused"/></span>
                  <span className="plusButton" onClick={() => createTransaction(balanceAccount, type, 1)}><FontAwesomeIcon icon={regularPlus} className="buttonUnfocused"/><FontAwesomeIcon icon={solidPlus} className="buttonFocused"/></span>
                  </>
                }
              </li>
            )
            }
        </ul>
      }
      <div className="worth">Worth: {numberFormatDecimal.format(totalWorth || 0)}</div>
      <div className="history">
        {
          config && transactions && balanceAccount && config.accounts[balanceAccount] && transactions.slice(transactions.findIndex(t => t.id === balanceTransactionId))
            .map(trans =>
            {
              const bothDC = [
                ...trans.debits.filter(d => d.account === balanceAccount).map(d => ({ ...d })), 
                ...trans.credits.filter(d => d.account === balanceAccount).map(c => ({ ...c, amount: -c.amount}))
              ];
              const squashedDC = util.groupAndReduce(bothDC, dc => dc.account + dc.type, (prev, curr) => ({ account: prev.account, type: prev.type, amount: prev.amount + curr.amount }));
              const typeKeys = Object.keys(config.types);
              const debits = squashedDC.filter(dc => dc.amount >= 0).sort((a, b) => typeKeys.findIndex(t => t === a.type) - typeKeys.findIndex(t => t === b.type));
              const credits = squashedDC.filter(dc => dc.amount < 0).map(dc => ({ ...dc, amount: -dc.amount })).sort((a, b) => typeKeys.findIndex(t => t === a.type) - typeKeys.findIndex(t => t === b.type))
              if (!trans.isNonPosting && (debits.length > 0 || credits.length > 0)) {
                return <div key={trans.id} className="historyrow" onClick={() => setBalanceTransactionId(trans.id)}>
                    <div className="historytext">{trans.text}</div>
                    <div>
                      {
                        debits.map(d => <div key={d.account+d.type} className="historyitem">{config.accounts[balanceAccount].increaseInCredit ? `- ${numberFormat.format(d.amount)}` : `+ ${numberFormat.format(d.amount)}`} {config.types[d.type].name}</div> )
                      }
                      {
                        credits.map(c => <div key={c.account+c.type} className="historyitem">{config.accounts[balanceAccount].increaseInCredit ? `+ ${numberFormat.format(c.amount)}` : `- ${numberFormat.format(c.amount)}`} {config.types[c.type].name}</div> )
                      }
                    </div>
                  </div>
              } else {
                return undefined;
              }
            }
          )
        }
      </div>
    </>
  );
}

export default AccountBalance;
