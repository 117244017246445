const utils = {
    groupAndReduce: (array, keyCallback, reduceCallback) => {
        const groups = {};
        for (const item of array) {
            const key = keyCallback(item);
            let groupMembers = groups[key];
            if (!groupMembers) {
                groupMembers = [];
                groups[key] = groupMembers;
            }
            groupMembers.push(item);
        }

        return Object.values(groups).map(items => items.reduce((prev, current, index) => {
            if (index === 0) {
                return current;
            } else {
                return reduceCallback(prev, current, index);
            }
        }));
    } 
};

export default utils;