import { useCallback, useState } from 'react';
import util from './util';
import './UseAction.css';

const makeInitialActionInput = action => {
  return action.sections.map(section => {
    const typeValues = {};
    for (let type of section.itemTypes) {
      typeValues[type] = 0;
    }
    return typeValues;
  });
};

function UseAction({ action, config, cancelAction, confirmAction, addTypeConfig }) {
  const [actionInput, setActionInput] = useState(makeInitialActionInput(action));
  const [comment, setComment] = useState("");
  const [addedTypeNames, setAddedTypeNames] = useState([]);

  const addTypeRow = useCallback((sectionIndex, value) => {
    if (value === "new") {
      addedTypeNames[sectionIndex] = "";
      setAddedTypeNames([...addedTypeNames]);
      return;
    }
    actionInput[sectionIndex][value] = 0;
    setActionInput({ ...actionInput });
  }, [actionInput, setActionInput, addedTypeNames, setAddedTypeNames]);

  const updateAmount = useCallback((sectionIndex, type, value) => {
    actionInput[sectionIndex][type] = Number(value);
    setActionInput({ ...actionInput });
  }, [actionInput, setActionInput]);

  const callConfirmAction = useCallback(() => {
    const transaction = {
      text: action.transactionText,
      credits: action.sections.flatMap((section, sectionIndex) =>
        Object.keys(actionInput[sectionIndex]).map(type => ({ account: section.creditAccount, type: type, amount: actionInput[sectionIndex][type] }))
        .filter(row => row.amount !== 0)
      ),
      debits: action.sections.flatMap((section, sectionIndex) =>
        Object.keys(actionInput[sectionIndex]).map(type => ({ account: section.debitAccount, type: type, amount: actionInput[sectionIndex][type] }))
        .filter(row => row.amount !== 0)
      ),
      comment: comment
    };
    // We should probably move towards allowing multiple debits/credits of the same account and type, but for now we do this.
    transaction.credits = util.groupAndReduce(transaction.credits, (c => c.account + c.type), (prev, curr) => ({ account: prev.account, type: prev.type, amount: prev.amount + curr.amount }));
    transaction.debits = util.groupAndReduce(transaction.debits, (d => d.account + d.type), (prev, curr) => ({ account: prev.account, type: prev.type, amount: prev.amount + curr.amount }));
    confirmAction(transaction);
  }, [action, comment, actionInput, confirmAction]);

  const updateAddedTypeNames = useCallback((sectionIndex, typeName) => {
    addedTypeNames[sectionIndex] = typeName;
    setAddedTypeNames([...addedTypeNames]);
  }, [addedTypeNames, setAddedTypeNames]);

  const addNewType = useCallback((sectionIndex) => {
    (async () => {
      const newType = await addTypeConfig(addedTypeNames[sectionIndex].trim());
      addedTypeNames[sectionIndex] = undefined;
      setAddedTypeNames([...addedTypeNames]);
      addTypeRow(sectionIndex, newType);
    })();
  }, [addTypeConfig, addedTypeNames, setAddedTypeNames, addTypeRow]);

  const selectAll = useCallback(e => e.target.select(), []);

  return (
    <>
      <div className="useaction">
        {
          action.sections.map((section, sectionIndex) => 
            <div className='section' key={sectionIndex}>
              <div className='sectionname'>{section.heading}</div>
              {
                Object.keys(actionInput[sectionIndex]).map((type, typeIndex) =>
                  <div className='type' key={type}>
                    <div className='typeamount'><input autoFocus={sectionIndex === 0 && typeIndex === 0} type='text' value={actionInput[sectionIndex][type]} onChange={e => updateAmount(sectionIndex, type, e.target.value)} onFocus={selectAll}></input></div>
                    <div className='typename'>{config.types[type].name}</div>
                  </div>
                )
              }
              {
                <div className="addtype">
                  <span>Add type</span>
                  {
                    addedTypeNames[sectionIndex] === undefined ?
                    <select value="" onChange={e => addTypeRow(sectionIndex, e.target.value)}>
                      <option key="empty" value="" hidden></option>
                      { Object.keys(config.types).filter(type => actionInput[sectionIndex][type] === undefined).map(type => <option key={type} value={type}>{config.types[type].name}</option>) }
                      <option key="new" value="new">&lt;new...&gt;</option>
                    </select>
                    :
                    <>
                      <input type="text" value={addedTypeNames[sectionIndex]} onChange={e => updateAddedTypeNames(sectionIndex, e.target.value)} placeholder="New type name..." autoFocus></input>
                      <button className="addnewtypebutton" onClick={() => addNewType(sectionIndex)}>Add</button>
                      <button className="addnewtypebutton" onClick={() => updateAddedTypeNames(sectionIndex, null)}>Cancel</button>
                    </>
                  }
                </div>
              }
            </div>
          )
        }
        <textarea rows='2' className='additionalcomment' placeholder='Additional comment' value={comment} onChange={e => setComment(e.target.value)}/>
      </div>
      <footer>
        <div className="right">
          <button onClick={callConfirmAction}>OK</button>
          <button onClick={cancelAction}>Cancel</button>
        </div>
      </footer>
    </>
  );
}

export default UseAction;
