import { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt as regularTrash } from '@fortawesome/free-regular-svg-icons';
import { faPlusSquare as solidPlus, faTrashAlt as solidTrash, faEllipsisH as solidEllipsis, faAngleLeft as angleLeft } from '@fortawesome/free-solid-svg-icons';
import SelectAction from './SelectAction';
import UseAction from './UseAction';
import './EditTransaction.css';
import Dialog from './Dialog';

const decomposeDebitCredit = (debits, credits) => {
  const result = {};
  for (let debit of debits) {
    const typeRow = result[debit.type] || { debit: {}, credit: {} };
    typeRow.debit[debit.account] = debit.amount;
    result[debit.type] = typeRow;
  }
  for (let credit of credits) {
    const typeRow = result[credit.type] || { debit: {}, credit: {} };
    typeRow.credit[credit.account] = credit.amount;
    result[credit.type] = typeRow;
  }
  return result;
};

const recomposeDebitCredit = (typeRows) => {
  const types = Object.keys(typeRows);
  return {
    debits: types.map(type => {
      const accounts = Object.keys(typeRows[type].debit);
      return accounts.map(account => ({ account, type, amount: typeRows[type].debit[account] }));
    }).flat(),
    credits: types.map(type => {
      const accounts = Object.keys(typeRows[type].credit);
      return accounts.map(account => ({ account, type, amount: typeRows[type].credit[account] }));
    }).flat(),
  };
};

const typeAmountDiff = (typeRow) => {
  const debitAmounts = Object.values(typeRow.debit);
  const creditAmounts = Object.values(typeRow.credit);
  const debitSum = debitAmounts.reduce((prev, curr) => prev + curr, 0);
  const creditSum = creditAmounts.reduce((prev, curr) => prev + curr, 0);
  return debitSum - creditSum;
};

const isTransactionValid = (typeRows) => {
  const types = Object.keys(typeRows);
  for (let type of types) {
    if (typeAmountDiff(typeRows[type]) !== 0) {
      return false;
    }
  }

  return true;
};

function EditTransaction({ actions, config, transactionTemplate, cancelEdit, saveTransaction, deleteTransaction, moveTransaction, addTypeConfig, addAccountConfig, allTransactions }) {
  const [id, setId] = useState(transactionTemplate.id);
  const [text, setText] = useState(transactionTemplate.text);
  const [comment, setComment] = useState(transactionTemplate.comment);
  const [customDate, setCustomDate] = useState(transactionTemplate.customDate);
  const [typeRows, setTypeRows] = useState(decomposeDebitCredit(transactionTemplate.debits, transactionTemplate.credits));
  const [isNonPosting, setNonPosting] = useState(!!transactionTemplate.isNonPosting);
  const [showActionSelectDialog, setShowActionSelectDialog] = useState(false);
  const [showActionDialog, setShowActionDialog] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [addedTypeName, setAddedTypeName] = useState(null);
  const [addedAccountNames, setAddedAccountNames] = useState({});
  const [footerState, setFooterState] = useState("normal");
  const [moveAfterId, setMoveAfterId] = useState("");
  const transactionValidity = isTransactionValid(typeRows);

  const callSaveTransaction = useCallback(() => {
    if (!text || !isTransactionValid(typeRows)) {
      return;
    }
    const finishedTransaction = {
      id: id,
      timestamp: transactionTemplate.timestamp,
      text: text,
      comment: comment,
      customDate: (customDate ?? '').trim(),
      isNonPosting: isNonPosting,
      ...recomposeDebitCredit(typeRows)
    };
    saveTransaction(finishedTransaction);
  }, [transactionTemplate, id, text, comment, customDate, isNonPosting, typeRows, saveTransaction]);

  const callDeleteTransaction = useCallback(() => {
    deleteTransaction(id);
  }, [deleteTransaction, id]);

  const copyTransaction = useCallback(() => {
    setId(null);
    setText("Copy of " + text);
  }, [setId, setText, text]);

  const callMoveTransaction = useCallback(() => {
    moveTransaction(id, moveAfterId);
  }, [id, moveAfterId, moveTransaction]);

  const autoEqualize = useCallback((type, modifiedAccount, debitOrCredit, newValue, typeRows) => {
    const diff = typeAmountDiff(typeRows[type]);
    if (diff) {
      if (newValue === '0') { // Auto-fill if we write 0 in the current box
        if (debitOrCredit === 'debit' && diff < 0 || debitOrCredit === 'credit' && diff > 0) {
          typeRows[type][debitOrCredit][modifiedAccount] = Math.abs(diff);
          setTypeRows({ ...typeRows });
        }
      } else if (debitOrCredit === 'debit' && diff > 0 || debitOrCredit === 'credit' && diff < 0) { // Auto-fill zeros on opposing side
        const opposing = debitOrCredit === 'debit' ? 'credit' : 'debit';
        const accounts = Object.keys(typeRows[type][opposing]);
        const zeroAccounts = accounts.filter(account => typeRows[type][opposing][account] === 0);
        
        if (zeroAccounts.length === 0) {
          return;
        }

        let leftToDistribute = Math.abs(diff);
        let accountsLeft = zeroAccounts.length;
        for (let account of zeroAccounts) {
          const perAccount = Math.ceil(leftToDistribute/accountsLeft);
          typeRows[type][opposing][account] = perAccount;
          leftToDistribute -= perAccount;
          accountsLeft -= 1;
        }
        setTypeRows({ ...typeRows });
      }
    }
  }, [setTypeRows]);

  const addTypeRow = useCallback((type) => {
    if (type === "new") {
      setAddedTypeName("");
      return;
    }
    setTypeRows({
      ...typeRows,
      [type]: {
        debit: {},
        credit: {}
      }
    })
  }, [typeRows, setTypeRows, setAddedTypeName]);

  const addNewType = useCallback(() => {
    (async () => {
      const newType = await addTypeConfig(addedTypeName.trim());
      setAddedTypeName(null);
      addTypeRow(newType);
    })();
  }, [addTypeConfig, addedTypeName, setAddedTypeName, addTypeRow]);

  const addDebitAccount = useCallback((type, account) => {
    if (type === "") {
      return;
    }
    if (account === "new") {
      setAddedAccountNames({ ...addedAccountNames, [type + "_debit"]: "" });
      return;
    }
    typeRows[type].debit[account] = 0;
    setTypeRows({ ...typeRows });
    autoEqualize(type, account, 'debit', '0', typeRows);
  }, [typeRows, setTypeRows, autoEqualize, addedAccountNames, setAddedAccountNames]);

  const addCreditAccount = useCallback((type, account) => {
    if (type === "") {
      return;
    }
    if (account === "new") {
      setAddedAccountNames({ ...addedAccountNames, [type + "_credit"]: "" });
      return;
    }
    typeRows[type].credit[account] = 0;
    setTypeRows({ ...typeRows });
    autoEqualize(type, account, 'credit', '0', typeRows);
  }, [typeRows, setTypeRows, autoEqualize, addedAccountNames, setAddedAccountNames]);

  const addNewAccount = useCallback((type, debitOrCredit) => {
    (async () => {
      if (debitOrCredit === "debit") {
        const newAccount = await addAccountConfig(addedAccountNames[type + "_debit"].trim(), false);
        setAddedAccountNames({ ...addedAccountNames, [type + "_debit"]: undefined });
        addDebitAccount(type, newAccount);
      } else {
        const newAccount = await addAccountConfig(addedAccountNames[type + "_credit"].trim(), true);
        setAddedAccountNames({ ...addedAccountNames, [type + "_credit"]: undefined });
        addCreditAccount(type, newAccount);
      }
    })();
  }, [addAccountConfig, addedAccountNames, setAddedAccountNames, addDebitAccount, addCreditAccount]);

  const updateDebitAmount = useCallback((type, account, amount) => {
    const parsed = parseInt(amount);
    if (Number.isNaN(parsed) || parsed < 0) {
      return;
    }
    typeRows[type].debit[account] = parsed;
    setTypeRows({ ...typeRows });
  }, [typeRows, setTypeRows]);

  const updateCreditAmount = useCallback((type, account, amount) => {
    const parsed = parseInt(amount);
    if (Number.isNaN(parsed) || parsed < 0) {
      return;
    }
    typeRows[type].credit[account] = parsed;
    setTypeRows({ ...typeRows });
  }, [typeRows, setTypeRows]);

  const removeDebitAccount = useCallback((type, account) => {
    delete typeRows[type].debit[account];
    setTypeRows({ ...typeRows });
  }, [typeRows, setTypeRows]);

  const removeCreditAccount = useCallback((type, account) => {
    delete typeRows[type].credit[account];
    setTypeRows({ ...typeRows });
  }, [typeRows, setTypeRows]);

  const applyAction = useCallback(() => {
    setShowActionSelectDialog(true);
  }, [setShowActionSelectDialog]);

  const actionSelected = useCallback(action => {
    setShowActionSelectDialog(false);
    setShowActionDialog(true);
    setSelectedAction(action);
  }, [setShowActionSelectDialog, setShowActionDialog, setSelectedAction]);

  const actionUsed = useCallback(transaction => {
    setShowActionDialog(false);
    const actionTypeRows = decomposeDebitCredit(transaction.debits, transaction.credits);
    for (let itemType of Object.keys(actionTypeRows)) {
      if (typeRows[itemType]) {
         for (let account of Object.keys(actionTypeRows[itemType].credit)) {
             typeRows[itemType].credit[account] = (typeRows[itemType].credit[account] || 0) + actionTypeRows[itemType].credit[account];
         }
         for (let account of Object.keys(actionTypeRows[itemType].debit)) {
          typeRows[itemType].debit[account] = (typeRows[itemType].debit[account] || 0) + actionTypeRows[itemType].debit[account];
        }
      } else {
        typeRows[itemType] = actionTypeRows[itemType];
      }
    }
    setTypeRows({ ...typeRows });
    let comments = [];
    if (comment !== undefined && comment !== "") {
      comments.push(comment);
    }
    if (transaction.comment !== undefined && transaction.comment !== "") {
      comments.push(transaction.comment);
    }
    setComment(comments.join("; "));
  }, [setShowActionDialog, typeRows, comment, setTypeRows]);

  const updateAddedAccountNames = useCallback((type, debitOrCredit, newName) => {
    setAddedAccountNames({ ...addedAccountNames, [type + "_" + debitOrCredit]: newName });
  }, [setAddedAccountNames, addedAccountNames]);

  const selectAll = useCallback(e => e.target.select(), []);

  let message = null;
  if (!text) {
    message = "A transaction text is required";
  }
  if (!transactionValidity) {
    message = "Debit and credit amounts must add up";
  }

  return (
    <>
      <div className={"edittransaction" + (isNonPosting ? " nonposting" : "") + (footerState === 'move' ? " moving" : "")}>
        <div className="topinputs">
          <input autoFocus className='transactiontext' type="text" placeholder='Transaction text' value={text} onChange={e => setText(e.target.value)}/>
          <input className='customdate' type="text" placeholder='Custom date' value={customDate} onChange={e => setCustomDate(e.target.value)}/>
        </div>
        <div className='typedebitcreditlabel'>
          <div>&nbsp;debit&nbsp;</div>
          <div>&nbsp;credit&nbsp;</div>
        </div>
        {
          Object.keys(typeRows).map(type => 
            <div className='typerow' key={type}>
              <div className='typename'>{config.types[type].name}</div>
               <div className='typedebitcredit'>
                <div className='typedebit'>
                  {
                    Object.keys(typeRows[type].debit).map(account =>
                      <div key={account} className="typerowitem">
                        <span className="removeaccountbutton" onClick={() => removeDebitAccount(type, account)}>
                          <FontAwesomeIcon icon={regularTrash} className="buttonUnfocused"/>
                          <FontAwesomeIcon icon={solidTrash} className="buttonFocused"/>
                        </span>
                        <div>{config.accounts[account].name}</div>
                        <input autoFocus type='text' value={typeRows[type].debit[account]} onChange={e => updateDebitAmount(type, account, e.target.value)} onBlur={e => autoEqualize(type, account, 'debit', e.target.value, typeRows)} onFocus={selectAll} />
                      </div>
                    )
                  }
                  <div className="typerowitem">
                    <span className="addaccounticon">
                      <FontAwesomeIcon icon={solidPlus} />
                    </span>
                    { addedAccountNames[type + "_debit"] === undefined ?
                      <select value="" onChange={e => addDebitAccount(type, e.target.value)}>
                        <option value="" hidden />
                        <option key="new" value="new">&lt;new...&gt;</option>
                        {
                          Object.keys(config.accountGroups).map(groupId => 
                            <optgroup key={groupId} label={config.accountGroups[groupId]}>
                              {
                                Object.keys(config.accounts)
                                  .filter(accountId => config.accounts[accountId].groupId === groupId && typeRows[type].debit[accountId] === undefined)
                                  .map(accountId => <option key={accountId} value={accountId}>{config.accounts[accountId].name}</option>)
                              }
                            </optgroup>
                          )
                        }
                      </select>
                      :
                      <>
                      <input className="addnewaccountname" type="text" value={addedAccountNames[type + "_debit"]} onChange={e => updateAddedAccountNames(type, "debit", e.target.value)} placeholder="New account..." autoFocus></input>
                      <button className="addnewaccountbutton" onClick={() => addNewAccount(type, "debit")}>Add</button>
                      <button className="addnewaccountbutton" onClick={() => updateAddedAccountNames(type, "debit", undefined)}>Cancel</button>
                      </>
                    }
                  </div>
                </div>
                <div className='typecredit'>
                  {
                    Object.keys(typeRows[type].credit).map(account =>
                      <div key={account} className="typerowitem">
                        <div>{config.accounts[account].name}</div>
                        <input autoFocus type='text' value={typeRows[type].credit[account]} onChange={e => updateCreditAmount(type, account, e.target.value)} onBlur={e => autoEqualize(type, account, 'credit', e.target.value, typeRows)} onFocus={selectAll} />
                        <span className="removeaccountbutton" onClick={() => removeCreditAccount(type, account)}>
                          <FontAwesomeIcon icon={regularTrash} className="buttonUnfocused"/>
                          <FontAwesomeIcon icon={solidTrash} className="buttonFocused"/>
                        </span>
                      </div>
                    )
                  }
                  <div className="typerowitem">
                    { addedAccountNames[type + "_credit"] === undefined ?
                      <select value="" onChange={e => addCreditAccount(type, e.target.value)}>
                        <option value="" hidden />
                        <option key="new" value="new">&lt;new...&gt;</option>
                        {
                          Object.keys(config.accountGroups).map(groupId => 
                            <optgroup key={groupId} label={config.accountGroups[groupId]}>
                              {
                                Object.keys(config.accounts)
                                  .filter(accountId => config.accounts[accountId].groupId === groupId && typeRows[type].credit[accountId] === undefined)
                                  .map(accountId => <option key={accountId} value={accountId}>{config.accounts[accountId].name}</option>)
                              }
                            </optgroup>
                          )
                        }
                      </select>
                      :
                      <>
                      <input className="addnewaccountname" type="text" value={addedAccountNames[type + "_credit"]} onChange={e => updateAddedAccountNames(type, "credit", e.target.value)} placeholder="New account..." autoFocus></input>
                      <button className="addnewaccountbutton" onClick={() => addNewAccount(type, "credit")}>Add</button>
                      <button className="addnewaccountbutton" onClick={() => updateAddedAccountNames(type, "credit", undefined)}>Cancel</button>
                      </>
                    }
                    <span className="addaccounticon">
                      <FontAwesomeIcon icon={solidPlus} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        {
          <div className="addtype">
            <span>Add affected item type</span>
            { addedTypeName === null ?
              <select value="" onChange={e => addTypeRow(e.target.value)}>
                <option key="empty" value=""></option>
                { Object.keys(config.types).filter(type => !typeRows[type]).map(type => <option key={type} value={type}>{config.types[type].name}</option>) }
                <option key="new" value="new">&lt;new...&gt;</option>
              </select>
              :
              <>
                <input type="text" value={addedTypeName} onChange={e => setAddedTypeName(e.target.value)} placeholder="New type name..." autoFocus></input>
                <button className="addnewtypebutton" onClick={addNewType}>Add</button>
                <button className="addnewtypebutton" onClick={() => setAddedTypeName(null)}>Cancel</button>
              </>
            }
          </div>
        }
        <textarea rows='2' className='transactioncomment' placeholder='Comment' value={comment} onChange={e => setComment(e.target.value)}/>
      </div>
      {
        {
          "normal":
            <footer>
              <div className="left">
                <button onClick={applyAction}>+Action</button>
                <button onClick={() => setFooterState("ellipsis")}><FontAwesomeIcon icon={solidEllipsis} /></button>
              </div>
              <div className="right">
                { message && <span>{message}</span> }
                <button disabled={!!message} onClick={callSaveTransaction}>Save</button>
                <button onClick={cancelEdit}>Cancel</button>
              </div>
            </footer>,
          "delete":
            <footer>
              <div className="right">
                <span>Really delete?</span>
                <button onClick={callDeleteTransaction} className='deletetrans'>Delete</button>
                <button onClick={() => setFooterState("normal")}>Cancel</button>
              </div>
            </footer>,
          "ellipsis":
            <footer>
              <div className="left">
                <button onClick={() => setFooterState("normal")}><FontAwesomeIcon icon={angleLeft} /></button>
                <button onClick={() => { setNonPosting(!isNonPosting); setFooterState("normal") }}>{ isNonPosting ? "Mark as posting" : "Mark as non-posting" }</button>
                { id && <button onClick={() => { copyTransaction(); setFooterState("normal") }}>Copy</button> }
                { id && allTransactions.length > 1 && <button onClick={() => setFooterState("move")}>Move</button> }
                { id && <button onClick={() => setFooterState("delete")} className='deletetrans'>
                    <FontAwesomeIcon icon={regularTrash} />
                  </button>
                }
              </div>
            </footer>,
          "move":
            <footer>
              <div className="right">
                <span>Will discard changes!</span> Move after:
                <select value={moveAfterId} onChange={(e) => setMoveAfterId(e.target.value)}>
                  <option value="" hidden></option>
                  {
                    allTransactions.filter(trans => trans.id !== id).map(trans => <option key={trans.id} value={trans.id}>{trans.text}</option>)
                  }
                </select>
                <button onClick={callMoveTransaction}>Move</button>
                <button onClick={() => setFooterState("normal")}>Cancel</button>
              </div>
            </footer>,
        }[footerState]
      }
      { showActionSelectDialog && <Dialog header="Select Action"><SelectAction actions={actions} openAction={actionSelected} cancel={() => setShowActionSelectDialog(false)}></SelectAction></Dialog> }
      { showActionDialog && <Dialog header="Use Action"><UseAction action={selectedAction} config={config} confirmAction={actionUsed} cancelAction={() => setShowActionDialog(false)} addTypeConfig={addTypeConfig}></UseAction></Dialog> }
    </>
  );
}

export default EditTransaction;
