import { useState, useEffect, useCallback } from 'react';
import { useParams } from "react-router-dom";
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare as regularPlus } from '@fortawesome/free-regular-svg-icons';
import { faPlusSquare as solidPlus, faEdit as solidEdit, faCog } from '@fortawesome/free-solid-svg-icons';
import './Ledger.css';
import backend from './backend';
import util from './util';
import Balances from './Balances';
import Dialog from './Dialog';
import NewTransaction from './NewTransaction';
import EditTransaction from './EditTransaction';
import Configuration from './Configuration/Configuration';
import UseAction from './UseAction';
import EditAction from './EditAction';

const numberFormat = new Intl.NumberFormat();

const emptyTransaction = {
  credits: [
  ],
  debits: [
  ]
};

function Ledger() {
  const params = useParams();
  const ledgerId = params.id;
  const [config, setConfig] = useState(null);
  const [actions, setActions] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showNewDialog, setShowNewDialog] = useState(false);
  const [showActionDialog, setShowActionDialog] = useState(false);
  const [transactionUnderEdit, setTransactionUnderEdit] = useState(emptyTransaction);
  const [selectedAction, setSelectedAction] = useState(null);
  const [showConfigDialog, setShowConfigDialog] = useState(false);
  const [showEditActionDialog, setShowEditActionDialog] = useState(false);
  const [selectedBalanceAccount, setSelectedBalanceAccount] = useState(null);
  
  useEffect(() => {
    (async () => {
      const response = await backend.fetchLedgerConfig(ledgerId);
      if (response.ok) {
        const newConfig = await response.json();
        setConfig(newConfig);
        document.title = newConfig.name + " - Inventarium";
      }
    })();
  }, [setConfig, ledgerId]);

  useEffect(() => {
    (async () => {
      const response = await backend.fetchLedgerActions(ledgerId);
      if (response.ok) {
        setActions(await response.json());
      }
    })();
  }, [setActions, ledgerId]);

  useEffect(() => {
    (async () => {
      const response = await backend.fetchLedgerTransactions(ledgerId);
      if (response.ok) {
        setTransactions(await response.json());
      }
    })();
  }, [setTransactions, ledgerId]);

  const openEditTransactionDialogWithEmpty = useCallback(() => {
    setShowNewDialog(false);
    setTransactionUnderEdit({...emptyTransaction});
    setShowEditDialog(true);
  }, [setShowEditDialog, setTransactionUnderEdit, setShowNewDialog]);

  const openEditTransactionDialogWithAction = useCallback(action => {
    setShowNewDialog(false);
    setSelectedAction(action);
    setShowActionDialog(true);
  }, [setShowNewDialog, setSelectedAction, setShowActionDialog]);
  
  const openEditTransactionDialog = useCallback((transactionIndex) => {
    setTransactionUnderEdit({...transactions[transactionIndex]});
    setShowEditDialog(true);
  }, [setShowEditDialog, setTransactionUnderEdit, transactions]);

  const saveTransaction = useCallback(transaction => {
    (async () => {
      if (!transaction.id) {
        await backend.insertLedgerTransaction(ledgerId, transaction);
      } else {
        await backend.updateLedgerTransaction(ledgerId, transaction);
      }
      const tResponse = await backend.fetchLedgerTransactions(ledgerId);
      if (tResponse.ok) {
        setTransactions(await tResponse.json());
      }
    })();
    setShowEditDialog(false);
  }, [setShowEditDialog, setTransactions, ledgerId]);

  const deleteTransaction = useCallback(transactionId => {
    (async () => {
      await backend.deleteLedgerTransaction(ledgerId, transactionId);
      const tResponse = await backend.fetchLedgerTransactions(ledgerId);
      if (tResponse.ok) {
        setTransactions(await tResponse.json());
      }
    })();
    setShowEditDialog(false);
  }, [setTransactions, setShowEditDialog, ledgerId]);

  const moveTransaction = useCallback((transactionId, moveAfterId) => {
    (async () => {
      await backend.moveLedgerTransaction(ledgerId, transactionId, moveAfterId);
      const tResponse = await backend.fetchLedgerTransactions(ledgerId);
      if (tResponse.ok) {
        setTransactions(await tResponse.json());
      }
    })();
    setShowEditDialog(false);
  }, [setTransactions, setShowEditDialog, ledgerId]);

  const createTransactionFromBalance = useCallback((account, type, amount) => {
    if (amount > 0 && !config.accounts[account].increaseInCredit) {
      setTransactionUnderEdit(
        {
          credits: [],
          debits: [ { account, type, amount: Math.abs(amount) } ]
        }
      );
    } else {
      setTransactionUnderEdit(
        {
          credits: [ { account, type, amount: Math.abs(amount) } ],
          debits: []
        }
      );
    }
    
    setShowEditDialog(true);
  }, [setTransactionUnderEdit, setShowEditDialog, config]);

  const createTransactionFromAction = useCallback(transaction => {
    setShowActionDialog(false);
    setTransactionUnderEdit(transaction);
    setShowEditDialog(true);
  }, [setShowActionDialog, setTransactionUnderEdit, setShowEditDialog]);

  const openConfigurationDialog = useCallback(() => {
    setShowConfigDialog(true);
  }, [setShowConfigDialog]);

  const saveConfig = useCallback(newConfig => {
    (async () => {
      const cResponse = await backend.saveConfig(ledgerId, newConfig);
      if (cResponse.ok) {
        const newConfig = await cResponse.json();
        setConfig(newConfig);
        document.title = newConfig.name + " - Inventarium";
        const tResponse = await backend.fetchLedgerTransactions(ledgerId);
        if (tResponse.ok) {
          setTransactions(await tResponse.json());
        }
      }
    })();
    setShowConfigDialog(false);
  }, [setShowConfigDialog, setTransactions, ledgerId]);

  const exportData = useCallback(() => {
    (async () => {
      const exportResponse = await backend.fetchDataExport(ledgerId);
      if (exportResponse.ok) {
        const objectUrl = window.URL.createObjectURL(await exportResponse.blob());
        const link = document.createElement('a');
        link.display = 'none';
        link.href = objectUrl;
        link.download = `Ledger ${config.name} - ${DateTime.now().toFormat('yyyy-MM-dd HH mm')}.json`;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    })();
  }, [ledgerId, config]);

  const addTypeConfig = useCallback(typeName => {
    return (async () => {
      const aResponse = await backend.addType(ledgerId, typeName);
      if (aResponse.ok) {
        const newType = await aResponse.json();
        const cResponse = await backend.fetchLedgerConfig(ledgerId);
        if (cResponse.ok) {
          setConfig(await cResponse.json());
        }
        return await newType.id;
      }
    })();
  }, [setConfig, ledgerId]);

  const addAccountConfig = useCallback((accountName, increaseInCredit) => {
    return (async () => {
      const aResponse = await backend.addAccount(ledgerId, accountName, increaseInCredit);
      if (aResponse.ok) {
        const newType = await aResponse.json();
        const cResponse = await backend.fetchLedgerConfig(ledgerId);
        if (cResponse.ok) {
          setConfig(await cResponse.json());
        }
        return await newType.id;
      }
    })();
  }, [setConfig, ledgerId]);

  const editActions = useCallback(() => {
    setShowConfigDialog(false);
    setShowEditActionDialog(true);
  }, [setShowConfigDialog, setShowEditActionDialog]);

  const saveAction = useCallback(action => {
    (async () => {
      let uResponse;
      if (!action.id) {
        uResponse = await backend.createAction(ledgerId, action);
      } else {
        uResponse = await backend.updateAction(ledgerId, action);
      }
      if (uResponse.ok) {
        const aResponse = await backend.fetchLedgerActions(ledgerId);
        if (aResponse.ok) {
          setActions(await aResponse.json());
        }
      }
    })();
  }, [setActions, ledgerId]);

  const deleteAction = useCallback(actionId => {
    (async () => {
      const uResponse = await backend.deleteAction(ledgerId, actionId);
      if (uResponse.ok) {
        const aResponse = await backend.fetchLedgerActions(ledgerId);
        if (aResponse.ok) {
          setActions(await aResponse.json());
        }
      }
    })();
  }, [setActions, ledgerId]);

  return (
    <>
      { showNewDialog && <Dialog header="New Transaction"><NewTransaction actions={actions} openRaw={openEditTransactionDialogWithEmpty} openAction={openEditTransactionDialogWithAction} cancel={() => setShowNewDialog(false)}></NewTransaction></Dialog> }
      { showActionDialog && <Dialog header="Use Action"><UseAction action={selectedAction} config={config} confirmAction={createTransactionFromAction} cancelAction={() => setShowActionDialog(false)} addTypeConfig={addTypeConfig}/></Dialog> }
      { showEditDialog && <Dialog header="Edit Transaction"><EditTransaction actions={actions} config={config} transactionTemplate={transactionUnderEdit} cancelEdit={() => setShowEditDialog(false)} saveTransaction={t => saveTransaction(t)} deleteTransaction={tId => deleteTransaction(tId)} addTypeConfig={addTypeConfig} moveTransaction={(tId, moveAfterId) => moveTransaction(tId, moveAfterId)} addAccountConfig={addAccountConfig} allTransactions={transactions}/></Dialog> }
      { showConfigDialog && <Dialog header="Configuration"><Configuration config={config} cancelConfig={() => setShowConfigDialog(false)} saveConfig={c => saveConfig(c)} editActions={editActions} exportData={exportData}/></Dialog> }
      { showEditActionDialog && <Dialog header="Edit Action"><EditAction config={config} actions={actions} cancel={() => setShowEditActionDialog(false)} save={a => saveAction(a)} deleteAction={id => deleteAction(id)} /></Dialog> }
      <div className="ledgerheading">
        {config ? config.name : 'Loading...'}
        <span className='buttons' onClick={openConfigurationDialog}><FontAwesomeIcon icon={faCog}/></span>
      </div>
      <div className="tabs">
        <div className="transactions">
          <div className="debitcreditheader">
            <div className='debitlabel'>debit</div>
            <div className='buttons' onClick={() => setShowNewDialog(true)}>
              <FontAwesomeIcon icon={regularPlus} className="buttonUnfocused"/>
              <FontAwesomeIcon icon={solidPlus} className="buttonFocused"/>
            </div>
            <div className='creditlabel'>credit</div>
          </div>
          { transactions && transactions.length === 0 && <div className="helpfulhint">You can create your first transaction by clicking the icon above</div>}
          {
            (transactions && config) ?
              transactions.map((trans, transIndex) => {
                const bothDC = [...trans.debits.map(d => ({ ...d })), ...trans.credits.map(c => ({ ...c, amount: -c.amount}))];
                const squashedDC = util.groupAndReduce(bothDC, dc => dc.account + dc.type, (prev, curr) => ({ account: prev.account, type: prev.type, amount: prev.amount + curr.amount }));
                const typeKeys = Object.keys(config.types);
                return {
                  ...trans,
                  debits: squashedDC.filter(dc => dc.amount >= 0).sort((a, b) => typeKeys.findIndex(t => t === a.type) - typeKeys.findIndex(t => t === b.type)),
                  credits: squashedDC.filter(dc => dc.amount < 0).map(dc => ({ ...dc, amount: -dc.amount })).sort((a, b) => typeKeys.findIndex(t => t === a.type) - typeKeys.findIndex(t => t === b.type))
                };
              }).map((trans, transIndex) =>
              <div className={"transaction" + (trans.isNonPosting ? " nonposting" : "")} key={trans.id} onClick={() => openEditTransactionDialog(transIndex)}>
                <div className="transheader">
                  <span className='editbutton'>
                    <FontAwesomeIcon icon={solidEdit} className="buttonFocused"/>
                  </span>
                  <span className='date'>{trans.customDate ? trans.customDate : DateTime.fromSeconds(trans.timestamp).toFormat('yyyy-MM-dd')}</span>
                  <span className='text'>{trans.text}</span>
                </div>
                <div className="transdetails">
                  <div className="debit">
                    { trans.debits.map(debit => 
                      <div key={debit.account + debit.type}>
                        <span className={"account " + (selectedBalanceAccount === debit.account ? "selectedBalance" : "")}>{config.accounts[debit.account].name}</span>
                        <span className="typeamount">{numberFormat.format(debit.amount)} {config.types[debit.type].name}</span>
                      </div>
                    ) }
                  </div>
                  <div className="credit">
                    { trans.credits.map(credit => 
                      <div key={credit.account + credit.type}>
                        <span className={"account " + (selectedBalanceAccount === credit.account ? "selectedBalance" : "")}>{config.accounts[credit.account].name}</span>
                        <span className="typeamount">{numberFormat.format(credit.amount)} {config.types[credit.type].name}</span>
                      </div>
                    ) }
                  </div>
                </div>
                <div className="transcomment">{trans.comment}</div>
              </div>
              )
            : ''
          }
        </div>
        <Balances ledgerId={ledgerId} transactions={transactions} config={config} createTransaction={createTransactionFromBalance} setSelectedBalanceAccount={setSelectedBalanceAccount}/>
      </div>
    </>
  );
}

export default Ledger;
