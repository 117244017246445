import { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare as regularPlus, faTrashAlt as regularTrash } from '@fortawesome/free-regular-svg-icons';
import { faPlusSquare as solidPlus, faTrashAlt as solidTrash } from '@fortawesome/free-solid-svg-icons';
import SelectAction from './SelectAction';
import Dialog from './Dialog';
import './EditAction.css';

const deepCopyAction = action => {
  const myCopy = { ...action };
  myCopy.sections = action.sections.map(section => ({
    ...section,
    itemTypes: [...section.itemTypes]
  }));
  return myCopy;
};

function EditAction({ config, actions, save, cancel, deleteAction }) {
  const [actionUnderEdit, setActionUnderEdit] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const actionSelected = useCallback(action => {
    setActionUnderEdit(deepCopyAction(action));
  }, [setActionUnderEdit]);

  const createNew = useCallback(() => {
    setActionUnderEdit({
      name: "",
      transactionText: "",
      sections: [{
        heading: "",
        debitAccount: Object.keys(config.accounts)[0],
        creditAccount: Object.keys(config.accounts)[0],
        itemTypes: []
      }]
    });
  }, [config, setActionUnderEdit]);

  const callSave = useCallback(() => {
    save(actionUnderEdit);
    setActionUnderEdit(null);
  }, [save, actionUnderEdit, setActionUnderEdit]);

  const callDeleteAction = useCallback(() => {
    deleteAction(actionUnderEdit.id);
    setActionUnderEdit(null);
  }, [deleteAction, actionUnderEdit, setActionUnderEdit])

  const copyAction = useCallback(() => {
    setActionUnderEdit({ ...actionUnderEdit, id: null, name: 'Copy of ' + actionUnderEdit.name });
  }, [actionUnderEdit, setActionUnderEdit]);

  const updateName = useCallback(name => {
    setActionUnderEdit({ ...actionUnderEdit, name: name });
  }, [actionUnderEdit, setActionUnderEdit]);

  const updateTransactionText = useCallback(text => {
    setActionUnderEdit({ ...actionUnderEdit, transactionText: text });
  }, [actionUnderEdit, setActionUnderEdit]);

  const updateSectionHeading = useCallback((sectionIndex, text) => {
    actionUnderEdit.sections[sectionIndex].heading = text;
    setActionUnderEdit({ ...actionUnderEdit });
  }, [actionUnderEdit, setActionUnderEdit]);

  const updateSectionDebitAccount = useCallback((sectionIndex, account) => {
    actionUnderEdit.sections[sectionIndex].debitAccount = account;
    setActionUnderEdit({ ...actionUnderEdit });
  }, [actionUnderEdit, setActionUnderEdit]);

  const updateSectionCreditAccount = useCallback((sectionIndex, account) => {
    actionUnderEdit.sections[sectionIndex].creditAccount = account;
    setActionUnderEdit({ ...actionUnderEdit });
  }, [actionUnderEdit, setActionUnderEdit]);

  const addSection = useCallback(() => {
    actionUnderEdit.sections.push({
      heading: "",
      debitAccount: Object.keys(config.accounts)[0],
      creditAccount: Object.keys(config.accounts)[0],
      itemTypes: []
    });
    setActionUnderEdit({ ...actionUnderEdit });
  }, [config, actionUnderEdit, setActionUnderEdit]);

  const addTypeRow = useCallback((sectionIndex, type) => {
    actionUnderEdit.sections[sectionIndex].itemTypes.push(type);
    setActionUnderEdit({ ...actionUnderEdit });
  }, [actionUnderEdit, setActionUnderEdit]);

  const removeTypeRow = useCallback((sectionIndex, type) => {
    actionUnderEdit.sections[sectionIndex].itemTypes =
      actionUnderEdit.sections[sectionIndex].itemTypes.filter(t => t !== type);
    setActionUnderEdit({ ...actionUnderEdit });
  }, [actionUnderEdit, setActionUnderEdit])

  const removeSection = useCallback(sectionIndex => {
    actionUnderEdit.sections.splice(sectionIndex, 1);
    setActionUnderEdit({ ...actionUnderEdit });
  }, [actionUnderEdit, setActionUnderEdit])

  let message = null;
  if (actionUnderEdit && !actionUnderEdit.name) {
    message = "Action must have a name";
  }
  if (actionUnderEdit && !actionUnderEdit.sections.every(s => s.heading)) {
    message = "All sections must have a heading";
  }

  return (
    <>
    { actionUnderEdit ?
      <>
        <div className="editaction">
          <div className='name'>
            <div className="header">Action name</div>
            <input autoFocus className='actionname' type="text" placeholder='Name' value={actionUnderEdit.name} onChange={e => updateName(e.target.value)}/>
          </div>
          <div className='text'>
            <div className="header">Transaction text</div>
            <input autoFocus className='transactiontext' type="text" placeholder='Text' value={actionUnderEdit.transactionText} onChange={e => updateTransactionText(e.target.value)}/>
          </div>
          <div className='sections'>
            <div className="header">Sections
              <span className="iconbutton" onClick={addSection}>
                <FontAwesomeIcon icon={regularPlus} className="buttonUnfocused"/>
                <FontAwesomeIcon icon={solidPlus} className="buttonFocused"/>
              </span>
            </div>
            {
              actionUnderEdit.sections.map((section, sectionIndex) =>
                <div className='section' key={sectionIndex}>
                  <div className='deletecol'>
                    <span className="iconbutton" onClick={() => removeSection(sectionIndex)}>
                      <FontAwesomeIcon icon={regularTrash} className="buttonUnfocused"/>
                      <FontAwesomeIcon icon={solidTrash} className="buttonFocused"/>
                    </span>
                  </div>
                  <div className='nontypecol'>
                    <div className='sectionHeading'>
                      <div className='subheader'>Section heading</div>
                      <input autoFocus className='sectionheading' type="text" placeholder='Heading' value={section.heading} onChange={e => updateSectionHeading(sectionIndex, e.target.value)}/>
                    </div>
                    <div className='sectionDebit'><div className='debitCreditHeading'><div className="subheader">Debit account</div></div>
                      <select value={section.debitAccount} onChange={e => updateSectionDebitAccount(sectionIndex, e.target.value)}>
                        {
                          Object.keys(config.accountGroups).map(groupId => 
                            <optgroup key={groupId} label={config.accountGroups[groupId]}>
                              {
                                Object.keys(config.accounts)
                                  .filter(accountId => config.accounts[accountId].groupId === groupId)
                                  .map(accountId => <option key={accountId} value={accountId}>{config.accounts[accountId].name}</option>)
                              }
                            </optgroup>
                          )
                        }
                      </select>
                    </div>
                    <div className='sectionCredit'><div className='debitCreditHeading'><div className="subheader">Credit account</div></div>
                      <select value={section.creditAccount} onChange={e => updateSectionCreditAccount(sectionIndex, e.target.value)}>
                        {
                          Object.keys(config.accountGroups).map(groupId => 
                            <optgroup key={groupId} label={config.accountGroups[groupId]}>
                              {
                                Object.keys(config.accounts)
                                  .filter(accountId => config.accounts[accountId].groupId === groupId)
                                  .map(accountId => <option key={accountId} value={accountId}>{config.accounts[accountId].name}</option>)
                              }
                            </optgroup>
                          )
                        }
                      </select>
                    </div>
                  </div>
                  <div className='typecol'>
                    <div className='sectionTypes'>
                      <div className="subheader">Default item types</div>
                      {
                        section.itemTypes.map(type =>
                          <div className='sectionType'>
                            <span className="iconbutton" onClick={() => removeTypeRow(sectionIndex, type)}>
                              <FontAwesomeIcon icon={regularTrash} className="buttonUnfocused"/>
                              <FontAwesomeIcon icon={solidTrash} className="buttonFocused"/>
                            </span>
                            {config.types[type].name}
                          </div>
                        )
                      }
                      <div className="addtype">
                        {
                          <select value="" onChange={e => addTypeRow(sectionIndex, e.target.value)}>
                            <option key="empty" value="" hidden>Add type...</option>
                            { Object.keys(config.types).filter(type => !section.itemTypes.includes(type)).map(type => <option key={type} value={type}>{config.types[type].name}</option>) }
                          </select>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
        { deleteConfirmation ?
          <footer>
            <div className="right">
              <span>Really delete?</span>
              <button onClick={callDeleteAction} className='deleteaction'>Delete</button>
              <button onClick={() => setDeleteConfirmation(false)}>Cancel</button>
            </div>
          </footer>
          :
          <footer>
            <div className="left">
              { actionUnderEdit.id && <button onClick={() => setDeleteConfirmation(true)} className='deleteaction'>
                  <FontAwesomeIcon icon={regularTrash} />
                </button>
              }
              { actionUnderEdit.id && <button onClick={copyAction}>Copy</button> }
            </div>
            <div className="right">
              { message && <span>{message}</span> }
              <button disabled={!!message} onClick={callSave}>Save</button>
              <button onClick={() => setActionUnderEdit(null)}>Cancel</button>
            </div>
          </footer>
        }
      </>
      :
      <SelectAction actions={actions} openAction={actionSelected} openNew={createNew} cancel={cancel}></SelectAction>
    }
    </>
  );
}

export default EditAction;
