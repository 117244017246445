import { useCallback, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare as regularPlus } from '@fortawesome/free-regular-svg-icons';
import { faPlusSquare as solidPlus } from '@fortawesome/free-solid-svg-icons';

function TypeConfiguration({ types, setTypes, stopAllEditsExcept, stopEditSignal }) {
  const [showAddType, setShowAddType] = useState(false);
  const [addTypeName, setAddTypeName] = useState("");
  const [editTypeId, setEditTypeId] = useState(null);

  const stopEdit = useCallback(() => {
    if (editTypeId) {
      setEditTypeId(null);
    }
  }, [editTypeId, setEditTypeId]);

  useEffect(() => {
    stopEdit();
  }, [stopEditSignal]);

  const addNew = useCallback(() => {
    types["new_" + new Date().getTime()] = { name: addTypeName, worth: 0 };
    setTypes(types);
    setAddTypeName("");
    setShowAddType(false);
  }, [types, addTypeName, setTypes, setAddTypeName, setShowAddType]);

  const startEdit = useCallback(typeId => {
    stopAllEditsExcept('types');
    setEditTypeId(typeId);
  }, [stopAllEditsExcept, setEditTypeId]);

  const setEditTypeName = useCallback((name) => {
    types[editTypeId] = { ...types[editTypeId], name };
    setTypes(types);
  }, [types, editTypeId, setTypes]);

  const setEditTypeWorth = useCallback((worth) => {
    types[editTypeId] = { ...types[editTypeId], worth };
    setTypes(types);
  }, [types, editTypeId, setTypes]);

  return (
    <div className="types">
      <div className="header">Item Types
        <span className="headeraddbutton" onClick={() => setShowAddType(true)}>
          <FontAwesomeIcon icon={regularPlus} className="buttonUnfocused"/>
          <FontAwesomeIcon icon={solidPlus} className="buttonFocused"/>
        </span>
      </div>
      <div className="typerow typerowheader"><div className="nameheader name">Name</div><div className="worthheader worth">Worth</div></div>
      { showAddType &&
        <div className="newtype">
          <input autoFocus className="newtypename" type="text" placeholder="Name" value={addTypeName} onChange={e => setAddTypeName(e.target.value)}></input>
          <button onClick={() => addNew()}>Add</button>
        </div>
      }
      {
        Object.keys(types).map(typeId =>
          <div className="typerow" key={typeId} onClick={(e) => e.stopPropagation()}>
          {
            editTypeId === typeId ?
              <div className="name"><input type="text" autoFocus value={types[typeId].name} onChange={e => setEditTypeName(e.target.value)} key={'edit_type_' + typeId}></input></div> :
              <div className="name" key={'type_' + typeId} onClick={() => startEdit(typeId)}>{types[typeId].name}</div>
          }
          {
            editTypeId === typeId ?
              <div className="worth"><input type="text" autoFocus value={types[typeId].worth} onChange={e => setEditTypeWorth(e.target.value)} key={'edit_worth_' + typeId}></input></div> :
              <div className="worth" key={'worth_' + typeId} onClick={() => startEdit(typeId)}>{types[typeId].worth}</div>
          }
          </div>
        )
      }
    </div>
  );
}

export default TypeConfiguration;
