const numberFormatDecimal = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

function BalanceSummary({ config, groupedAccounts, balance }) {
  const totalWorth = {};
  if (config && balance) {
    for (const balanceAccount of Object.keys(balance)) {
      totalWorth[balanceAccount] = Object.entries(balance[balanceAccount]).reduce((prev, [typeId, value]) => prev + config.types[typeId].worth * value, 0) || 0;
    }
  } 

  return (
    <div className="summary">
      {
        config && Object.keys(config.virtualAccounts).length > 0 &&
        <div className="summarygroup">
          <div className="groupname">Virtual accounts</div>
          <div className="accounts">
            { Object.keys(config.virtualAccounts).map(virtualAccountId =>
              <div className="account" key={virtualAccountId}>
                <div className="accountname">{config.virtualAccounts[virtualAccountId].name}</div>
                <div className="accountworth">{numberFormatDecimal.format(totalWorth[virtualAccountId] || 0)}</div>
              </div>
            )}
          </div>
        </div>
      }
      {
        config && Object.keys(config.accountGroups).map(groupId =>
          groupedAccounts[groupId] &&
          <div className="summarygroup">
            <div className="groupname">{config.accountGroups[groupId]}</div>
            <div className="accounts">
              { groupedAccounts[groupId].map(accountId =>
                <div className="account" key={accountId}>
                  <div className="accountname">{config.accounts[accountId].name}</div>
                  <div className="accountworth">{numberFormatDecimal.format(totalWorth[accountId] || 0)}</div>
                </div>
              )}
            </div>
          </div>
        ) 
      }
    </div>
  );
}

export default BalanceSummary;
