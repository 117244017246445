import './SelectAction.css';

function SelectAction({ actions, openAction, openNew, cancel }) {

  return (
    <div className="selectaction">
      { openNew && <div className="section">
          <div className="header"></div>
          <div className="option" onClick={openNew}>Create new action...</div>
        </div>
      }
      <div className="section">
        <div className="header">Action</div>
        { actions.map(action => <div className="option" key={action.name} onClick={() => openAction(action)}>{action.name}</div>) }
      </div>
      <div className="section">
        <div className="header"></div>
        <div className="option" onClick={cancel}>Cancel</div>
      </div>
    </div>
  );
}

export default SelectAction;
