import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import backend from './backend';
import './Balances.css';
import AccountBalance from './AccountBalance';
import BalanceSummary from './BalanceSummary';

function Balances({ ledgerId, transactions, config, createTransaction, setSelectedBalanceAccount }) {
  const [balance, setBalance] = useState({});
  const [balanceTransactionId, setBalanceTransactionId] = useState(0);
  const [balanceAccount, setBalanceAccount] = useState("");

  useEffect(() => {
    if (ledgerId && config && transactions && balanceTransactionId !== 0) {
      (async () => {
        const response = await backend.calculateAccountBalance(ledgerId, balanceTransactionId);
        if (response.ok) {
          setBalance(await response.json());
        }
      })();
    }
  }, [ledgerId, transactions, balanceTransactionId, config, setBalance]);

  useEffect(() => {
    if (balanceAccount !== "summary" && (balanceAccount === "" && Object.keys(balance).length > 0 || balance[balanceAccount] === undefined)) {
      setBalanceAccount("summary");
      setSelectedBalanceAccount("summary");
    }
  }, [balance, balanceAccount, setBalanceAccount, setSelectedBalanceAccount]);

  useEffect(() => {
    if (transactions && transactions.length > 0) {
      setBalanceTransactionId(transactions[0].id);
    }
  }, [transactions, setBalanceTransactionId]);

  const groupedAccounts = {};
  if (config && balance) {
    for (const accountId of Object.keys(balance)) {
      const account = config.accounts[accountId];
      const isNonVirtual = !!account;
      if (isNonVirtual) {
        const accounts = (groupedAccounts[account.groupId] || []);
        accounts.push(accountId);
        groupedAccounts[account.groupId] = accounts;
      }
    }
  }

  return (
    <div className="balance">
      <div className="balanceheader"><div className="balancelabel">balance and history</div></div>
      <select onChange={event => { setBalanceAccount(event.target.value); setSelectedBalanceAccount(event.target.value); } } value={balanceAccount}>
        <option key="summary" value="summary">- Summary -</option>
      {
        config && Object.keys(config.virtualAccounts).length > 0
        ? <optgroup label="Virtual accounts">
            { Object.keys(config.virtualAccounts).map(virtualAccountId =>
              <option key={virtualAccountId} value={virtualAccountId}>{config.virtualAccounts[virtualAccountId].name}</option>
            )}
          </optgroup>
        : null
      }
      {
        config && Object.keys(config.accountGroups).map(groupId =>
          groupedAccounts[groupId]
          ? <optgroup key={groupId} label={config.accountGroups[groupId]}>
              { groupedAccounts[groupId].map(accountId => <option key={accountId} value={accountId}>{config.accounts[accountId].name}</option>) }
            </optgroup>
          : undefined
        )
      }
      </select>
      <select className="balanceTransaction" onChange={event => setBalanceTransactionId(event.target.value) } value={balanceTransactionId}>
      {
        config && transactions && transactions.filter(trans => !trans.isNonPosting).map(trans =>
        <option key={trans.id} value={trans.id}>at {trans.customDate ? trans.customDate : DateTime.fromSeconds(trans.timestamp).toFormat('yyyy-MM-dd')} | {trans.text}</option>
        )
      }
      </select>
      {
        balanceAccount === "summary" ?
        <BalanceSummary config={config} groupedAccounts={groupedAccounts} balance={balance}></BalanceSummary> : 
        <AccountBalance
          config={config}
          balance={balance}
          balanceAccount={balanceAccount}
          transactions={transactions}
          balanceTransactionId={balanceTransactionId}
          createTransaction={createTransaction}
          setBalanceTransactionId={setBalanceTransactionId} />
      }
    </div>
  );
}

export default Balances;
