import { useCallback, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare as regularPlus } from '@fortawesome/free-regular-svg-icons';
import { faPlusSquare as solidPlus } from '@fortawesome/free-solid-svg-icons';
import './AccountConfiguration.css';

function AccountConfiguration({ accounts, setAccounts, groups, defaultGroupId, stopAllEditsExcept, stopEditSignal }) {
  const [showAddAccount, setShowAddAccount] = useState(false);
  const [addAccountName, setAddAccountName] = useState("");
  const [editAccountId, setEditAccountId] = useState(null);

  const stopEdit = useCallback(() => {
    if (editAccountId) {
      setEditAccountId(null);
    }
  }, [editAccountId, setEditAccountId]);

  useEffect(() => {
    stopEdit();
  }, [stopEditSignal]);

  const creditChangeCallback = useCallback((accountId) => {
    accounts[accountId] = { ...accounts[accountId], increaseInCredit: !accounts[accountId].increaseInCredit };
    setAccounts(accounts);
  }, [accounts, setAccounts]);

  const addNew = useCallback(() => {
    accounts["new_" + new Date().getTime()] = { name: addAccountName, groupId: defaultGroupId };
    setAccounts(accounts);
    setAddAccountName("");
    setShowAddAccount(false);
  }, [accounts, addAccountName, setAccounts, setAddAccountName, setShowAddAccount, defaultGroupId]);

  const startEdit = useCallback(accountId => {
    stopAllEditsExcept('accounts');
    setEditAccountId(accountId);
  }, [stopAllEditsExcept, setEditAccountId]);

  const setEditAccountGroup = useCallback((groupId) => {
    accounts[editAccountId].groupId = groupId;
    setAccounts(accounts);
  }, [accounts, editAccountId, setAccounts]);

  const setEditAccountName = useCallback((name) => {
    accounts[editAccountId].name = name;
    setAccounts(accounts);
  }, [accounts, editAccountId, setAccounts]);

  return (
    <div className="accounts">
      <div className="header">Accounts
        <span className="headeraddbutton" onClick={() => setShowAddAccount(true)}>
          <FontAwesomeIcon icon={regularPlus} className="buttonUnfocused"/>
          <FontAwesomeIcon icon={solidPlus} className="buttonFocused"/>
        </span>
      </div>
      <div className="accountrow accountrowheader"><div className="groupheader group">Group</div><div className="nameheader name">Name</div><div className="creditheader credit">Increases in credit</div></div>
      { showAddAccount &&
        <div className="newaccount">
          <input autoFocus className="newaccountname" type="text" placeholder="Name" value={addAccountName} onChange={e => setAddAccountName(e.target.value)}></input>
          <button onClick={() => addNew()}>Add</button>
        </div>
      }
      { Object.keys(accounts).map(accountId => 
        <div className="accountrow" key={accountId} onClick={(e) => e.stopPropagation()}>
          {
            editAccountId === accountId ?
              <div className="group">
                <select value={accounts[editAccountId].groupId} onChange={e => setEditAccountGroup(e.target.value)}>
                {
                  Object.keys(groups).map(groupId => <option key={groupId} value={groupId}>{groups[groupId]}</option>) 
                }
                </select>
              </div> : 
              <div className="group" onClick={() => startEdit(accountId)}>{groups[accounts[accountId].groupId] || accounts[accountId].groupId}</div>
          }
          {
            editAccountId === accountId ?
              <div className="name"><input type="text" autoFocus value={accounts[editAccountId].name} onChange={e => setEditAccountName(e.target.value)}></input></div> : 
              <div className="name" onClick={() => startEdit(accountId)}>{accounts[accountId].name}</div>
          }
          <div className="credit">
            <input type="checkbox" checked={!!accounts[accountId].increaseInCredit} onChange={e => creditChangeCallback(accountId)}></input>
          </div>
        </div>
      ) }
    </div>
  );
}

export default AccountConfiguration;
